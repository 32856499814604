.signs-editor-container {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.signs-editor-inner-container {
  position: relative;
  width: 700px;
  aspect-ratio: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signs-editor-bg-image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
}

.sign-editor {
  font: inherit;
  font-size: 420%;
  width: 90%;
  max-height: 90%;
  background-color: rgba(255, 255, 255, 0);
  border-width: 0px;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  resize: none;
  text-align: center;
}

/* @media (min-width: 1200px) { */
/*   .sign-editor { */
/*     font-size: 400%; */
/*   } */
/* } */

.ProseMirror p {
  font-size: 420%;
  text-align: center;
}

@media (max-width: 850px) {
  .ProseMirror p {
    font-size: 310%;
  }
  .sign-editor {
    font-size: 310%;
  }
  .signs-editor-inner-container {
    width: 500px;
  }
}

@media (max-width: 550px) {
  .ProseMirror p {
    font-size: 180%;
  }
  .sign-editor {
    font-size: 180%;
  }
  .signs-editor-inner-container {
    width: 300px;
  }
}

@media (max-width: 350px) {
  .ProseMirror p {
    font-size: 130%;
  }
  .sign-editor {
    font-size: 130%;
  }
  .signs-editor-inner-container {
    width: 220px;
  }
}

.wysiwyg-editor {
  color: black;
  max-height: 100%;
  overflow: hidden;
  width: 90%;
  margin: 0px;
  white-space: pre-wrap;
  border: 1px solid #ccc;
}

.sign-editor-button {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 75px;
}

.ProseMirror-menubar {
  background-color: rgba(255, 255, 255, 0.7);
}

.ProseMirror {

}
