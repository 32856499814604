.health {
  position: fixed;
  display: flex;
  z-index: -1;
  flex-direction: row;
  left: calc(50% - 91px);
  bottom: calc(var(--safe-area-inset-bottom) + 30px);
  --hardcore: 0;
  --kind: 0;
  --lightened: 0;
  --offset: calc(-1 * (52px + (9px * (4 * var(--kind) + var(--lightened) * 2)) ));
  --bg-x: calc(-1 * (16px + 9px * var(--lightened)));
  --bg-y: calc(-1 * var(--hardcore) * 45px);
  pointer-events: none;
  image-rendering: pixelated;
}

.health.creative {
  display: none;
}

.health.hardcore {
  --hardcore: 1;
}

.health.poisoned {
  --kind: 1;
}

.health.withered {
  --kind: 2;
}

.health.absorption {
  --kind: 3;
}

.heart {
  width: 9px;
  height: calc(9px - var(--hardcore) * 0.2px);
  background-image: var(--gui-icons);
  background-size: 256px, 256px;
  background-position: var(--bg-x) var(--bg-y), var(--bg-x) var(--bg-y);
  margin-left: -1px;
}

.heart.full {
  background-position: var(--offset) var(--bg-y), var(--bg-x) var(--bg-y);
}

.heart.half {
  background-position: calc(var(--offset) - 9px) var(--bg-y), var(--bg-x) var(--bg-y);
}

.health.low .heart {
  animation: lowHealthAnim 0.2s steps(2, end) infinite;
}

.health.low .heart:nth-of-type(2n) {
  animation-direction: reverse;
}

.health.low .heart:nth-of-type(3n) {
  animation-duration: 0.1s;
}

.health.damaged {
  animation: damagedAnim 0.3s steps(2, end) 2;
}

@keyframes lowHealthAnim {
  to {
    transform: translateY(1px);
  }
}

@keyframes damagedAnim {
  to { --lightened: 1; }
}
