.hint_container {
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  pointer-events: none;
  z-index: 1000;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);
}

.hint_text {
  color: white;
  font-size: 10px;
}
