.armor_container {
  position: fixed;
  display: flex;
  z-index: -1;
  flex-direction: row;
  left: calc(50% - 91px);
  bottom: calc(var(--safe-area-inset-bottom) + 40px);
  --offset: calc(-1 * 25px);
  --bg-x: calc(-1 * 16px);
  --bg-y: calc(-1 * 9px);
  pointer-events: none;
  image-rendering: pixelated;
}

.armor {
  width: 9px;
  height: calc(9px);
  background-image: var(--gui-icons);
  background-size: 256px, 256px;
  background-position: var(--bg-x) var(--bg-y), var(--bg-x) var(--bg-y);
  margin-left: -1px;
}


.armor.full {
  background-position: calc(var(--offset) - 9px) var(--bg-y), var(--bg-x) var(--bg-y);
}

.armor.half {
  background-position: var(--offset) var(--bg-y), var(--bg-x) var(--bg-y);
}
