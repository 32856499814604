.breathbar {
  position: fixed;
  display: flex;
  z-index: -1;
  flex-direction: row-reverse;
  left: calc(50% + 91px);
  transform: translate(-100%);
  bottom: 40px;
  --offset: calc(-1 * 16px);
  --bg-x: calc(-1 * 16px);
  --bg-y: calc(-1 * 18px);
  pointer-events: none;
  image-rendering: pixelated;
}

.breath {
  width: 9px;
  height: 9px;
  margin-left: -1px;
  background-position: 20px 20px;
  background-image: var(--gui-icons);
}

.breath.full {
  background-size: 256px;
  background-position: var(--offset) var(--bg-y);
}

.breath.half {
  background-size: 256px;
  background-position: calc(var(--offset) - 9px) var(--bg-y);
}
