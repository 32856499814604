.foodbar {
  position: fixed;
  display: flex;
  z-index: -1;
  flex-direction: row-reverse;
  left: calc(50% + 91px);
  transform: translate(-100%);
  bottom: calc(var(--safe-area-inset-bottom) + 30px);
  --kind: 0;
  --lightened: 0;
  --offset: calc(-1 * (52px + (9px * (4 * var(--kind) + var(--lightened) * 2))));
  --bg-x: calc(-1 * (16px + 9px * var(--lightened)));
  --bg-y: calc(-1 * 27px);
  pointer-events: none;
  image-rendering: pixelated;
}

.foodbar.poisoned {
  --kind: 1;
}

.foodbar.withered {
  --kind: 2;
}

.food {
  width: 9px;
  height: 9px;
  background-size: 256px, 256px;
  background-position: var(--bg-x) var(--bg-y), var(--bg-x) var(--bg-y);
  background-image: var(--gui-icons);
  margin-left: -1px;
}

.food.full {
  background-position: var(--offset) var(--bg-y), var(--bg-x) var(--bg-y);
}

.food.half {
  background-position: calc(var(--offset) - 9px) var(--bg-y), var(--bg-x) var(--bg-y);
}

.foodbar.low .food {
  animation: lowHungerAnim 0.2s steps(2, end) infinite;
}

.foodbar.low .food:nth-of-type(2n) {
  animation-direction: reverse;
}

.foodbar.low .food:nth-of-type(3n) {
  animation-duration: 0.1s;
}

.foodbar.updated {
  animation: updatedAnim 0.3s steps(2, end) 2;
}

.creative {
  display: none;
}

@keyframes lowHungerAnim {
  to { transform: translateY(1px); }
}

@keyframes updatedAnim {
  to { --lightened: 1; }
}
