@keyframes loading-chunks-loading-animation {
    /* blink */
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}
