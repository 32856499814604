.bossBars {
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: absolute;
  width: 100%;
  top: 1px;
  left: 50%;
  transform: translate(-50%);
  pointer-events: none;
}

.bossBars.mobile {
  top: 18px;
}

.bossbar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bossbar-title {
  font-size: 10px;
  text-align: center;
  color: #fff;
  margin-bottom: -1px;
  white-space: nowrap;
  overflow: hidden;
}
.bossbar {
  background-image: var(--bars-gui-atlas);
  width: 182px;
  height: 5px;
  position: relative;
}
.bossbar .fill {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 0;
  background-image: var(--bars-gui-atlas);
}
